import React from 'react';

import Container from '../Container/Container';

import DeliveryIcon from '../../assets/icons/delivery-car.svg';
import PaymentIcon from '../../assets/icons/payment/debit-card.svg';
import InfoIcon from '../../assets/icons/info.svg';
import PhoneIcon from '../../assets/icons/phone.svg';

import { deliveryMethods, paymentMethods, paymentMethodsDescription } from '../../constants/deliveryData';

import './Delivery.scss';

const Delivery: React.FC = () => {
  return (
    <div className="delivery">
      <Container>
        <h2 className="delivery__title">Оплата і доставка</h2>
        <p className="delivery__description">
          Після здійснення вами замовлення на нашому сайті, з вами зв`яжеться наш менеджер, для уточнення деталей та узгодження зручного для
          вас варіанту оплати та доставки товарів
        </p>
        <div className="delivery__methods">
          <div className="delivery__methods-item">
            <PaymentIcon />
            <h2>Види оплати</h2>
            {paymentMethods.map(({ id, title }) => (
              <p key={id}>{`${id}. ${title}`}</p>
            ))}
          </div>
          <div className="delivery__methods-item">
            <DeliveryIcon />
            <h2>Види доставки</h2>
            {deliveryMethods.map(({ id, title }) => (
              <p key={id}>{`${id}. ${title}`}</p>
            ))}
            <div className="delivery__methods-item-description">
              <InfoIcon />
              <p>Ви можете звернутися до нашої компанії і ми підберемо оптимальний для вас спосіб оплати та доставки товарів</p>
            </div>
          </div>
        </div>
        <div className="delivery__description">
          <h2>Детальніше про способи оплати</h2>
          <div className="delivery__description-items">
            {paymentMethodsDescription.map(({ id, title, description }) => (
              <div className="delivery__description-item" key={id}>
                <h2>{title}</h2>
                <p>{description}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="delivery__consult">
          <h2>
            Всі питання, пов&rsquo;язані з перевезенням і доставкою товару у Ваше місто, обговорюйте з нашими менеджерами за телефонами,
            вказаними нижче. Також пишіть нам через форму зворотного зв&rsquo;язку на сайті
          </h2>
          <p>Телефони для замовлення та консультацій</p>
          <div className="delivery__consult-phones">
            <div className="delivery__consult-phone">
              <PhoneIcon />{' '}
              <a href="tel:+380637300303" className="binct-phone-number-3">
                +380637300303
              </a>
            </div>
          </div>
        </div>
      </Container>
      <div className="blue-circle" />
      <div className="yellow-circle" />
    </div>
  );
};

export default Delivery;
