/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-expressions */
import React from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import Arrow from '../../assets/icons/arrow.svg';
import { ISubheaerProps } from './Types';
import './Subheader.scss';

const Subheader: React.FC<ISubheaerProps> = ({ crumbs, withContainer }): JSX.Element => {
  const breakpoints = useBreakpoint();
  return (
    <div className={`subheader${withContainer ? ' container-wrapper' : ''}`}>
      <Breadcrumbs crumbs={crumbs} />

      {!withContainer && (
        <button
          className="subheader-button"
          type="button"
          onClick={() => {
            typeof history !== 'undefined' && history.go(-1);
          }}
        >
          <Arrow />
          Назад
        </button>
      )}
    </div>
  );
};

export default Subheader;
