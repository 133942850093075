import React from 'react';

import Seo from '../components/SEO/SEO';
import Delivery from '../components/Delivery/Delivery';
import Subheader from '../components/Subheader/Subheader';

import { SEO_ITEMS } from '../constants/SEOItems';

const crumbs = [
  {
    title: 'Головна',
    link: '/',
  },
  {
    title: 'Оплата та доставка',
    link: '/delivery',
  },
];

const DeliveryPage: React.FC = (): JSX.Element => {
  return (
    <>
      <Seo
        breadcrumbs={crumbs}
        description={SEO_ITEMS.deliveryPage.description}
        lang="ua"
        path="/delivery"
        title={SEO_ITEMS.deliveryPage.title}
      />
      <Subheader crumbs={crumbs} withContainer />
      <Delivery />
    </>
  );
};

export default DeliveryPage;
